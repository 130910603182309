import React from "react"
import * as styles from './WorkspaceIcon.module.scss'
import Conference from './../../../images/icons/conference-sm-white.png'
import Training from './../../../images/icons/training-sm-white.png'

const WorkspaceIcon = ({color, type, format}) => {
    // color: blue, gray
    // type: conference, training
    // format: split, solid
    const iconTypes = {
        'conference': Conference,
        'training': Training
    }

    return(  
           <div className={`${styles.hexagonBG} ${styles[`${format}`]} ${styles[`${color}`]} ${format}-${color}Hex`}>
                <img src={iconTypes[type]} alt={`${type} icon`} width='32' height='24.8'/>
            </div>
    )
}

export default WorkspaceIcon