import React, { Fragment, useRef } from 'react'
import WorkspaceCard from './../../../components/UI/Workspaces/WorkspaceCard/WorkspaceCard'
import WorkspaceIcon from './../../../components/UI/WorkspaceIcons/WorkspaceIcon'
import SimpleButton from './../Buttons/SimpleButton/SimpleButton'

const Workspaces = () => {
  const trainingWorkspace = useRef()
  const conferenceWorkspace = useRef()

  const trainingModalContent = {
    title: 'Training Center',
    phone: '301-737-7119',
    email: '',
    text: 'If interested in utilizing the Precise Systems Training Center, please contact us at',
    address1: '22289 Exploration Park Dr. Suite #110',
    address2: 'Lexington Park MD 20653',
  }
  const conferenceModalContent = {
    title: 'Conference Center',
    phone: '301-737-7100',
    email: 'Sales@GoPrecise.com',
    text: 'To book a conference room, please contact the Precise Systems Receptionist at ',
    address1: '22290 Exploration Dr. 3rd Floor',
    address2: 'Lexington Park, MD 20653',
  }

  return (
    <Fragment>
      <WorkspaceCard
        ref={conferenceWorkspace}
        type="conference"
        content={conferenceModalContent}
      >
        <WorkspaceIcon type="conference" color="colored" format="split" />
        <header>Conference Center</header>
        <p>
          Exceptional facilities accommodating various sized groups for
          meetings, non classified and classified, as well as space for small
          breakout sessions!
        </p>
        <SimpleButton
          type="blue"
          text="View Space"
          buttonHandler={() => conferenceWorkspace.current.openModal()}
        />
      </WorkspaceCard>
      {/* <WorkspaceCard ref={trainingWorkspace} type='training' content={trainingModalContent}>
                <WorkspaceIcon type='training' color='colored' format='split'/>
                <header>Training Center</header>
                <p>Top of the line training facility with high-end presentation equipment to best accommodate any class! </p>
                <SimpleButton type='blue' text='View Space' buttonHandler={() => trainingWorkspace.current.openModal()}/>
              </WorkspaceCard> */}
    </Fragment>
  )
}

export default Workspaces
