import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react"
import Portal from "./../Portal/Portal"

import * as styles from './Modal.module.scss'

const Modal = forwardRef(({children, type='light'}, ref) => {
  // type: light, dark

  const [display, setDisplay] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
      }
    }
  )

  const handleOpen = () => {
    setDisplay(true);
  }

  const handleClose = () => {
    setDisplay(false);
  }

  useEffect(() => {
    const close = e => {

        if (e.keyCode === 27) {
            handleClose();
        }
    }
  
    
    window.addEventListener('keydown', close)
    return () => {
        window.removeEventListener('keydown', close)
        window.addEventListener('click', close)
    }
  }, []);

  if (display) {
    return (
      <Portal>
          <div aria-label="Close" tabindex="0" role="button" onClick={handleClose} onKeyDown={handleClose} className={`${styles[`${type}`]} ${styles.modal_backdrop}`}></div>
          <div className={`${styles.modal_container} ${styles[`${type}`]}`}>
             <button aria-label="Close" className={styles.closeBtn} onKeyDown={handleClose} onClick={() => { setDisplay(false);}}>Close</button>
            {children}
          </div>
      </Portal>
    )
  }

  return null
})

export default Modal