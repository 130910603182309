import React from 'react'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import Services from '../components/UI/Services/Services'
import Callout from './../components/Callouts/BannerCallout/BannerCallout'
import './../styles/pageSpecific/home.scss'

import CultureIcon from './../images/icons/precise-culture-icon.png'
import PeopleIcon from './../images/icons/precise-people-icon.png'
import QualityIcon from './../images/icons/precise-quality-icon.png'
import Workspaces from '../components/UI/Workspaces/Workspaces'

const IndexPage = () => {
  const pageInfo = {
    pageType: 'home',
    title1: 'Precise',
    title2: 'Home',
    breadcrumbs: [],
    ctaButton: true,
    ctaButtonData: {
      text: 'Learn More About Us',
      url: '/who-we-are',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Service section */}
        <SectionContainer id="mainServices" color="white" type="fullWidth">
          <SectionHeader
            color="navy"
            alignment="center"
            size="med"
            trim="true"
            tag="Our teams are comprised of highly-skilled professionals who provide expert solutions &amp; cutting edge support to the warfighter. "
          >
            Our <span>Capabilities</span>
          </SectionHeader>
          <Services color="colored" />
        </SectionContainer>

        <Callout color="colored" />

        {/* proud of our section */}
        <SectionContainer
          id="pride"
          topBorder={true}
          color="navy"
          type="fullWidth"
        >
          <SectionHeader
            color="white"
            alignment="center"
            size="med"
            trim="true"
          >
            We Are <span>Proud Of Our</span>
          </SectionHeader>
          <section className="inlineChildren_smMobile pride__aspects">
            <div className="pride__aspectWrap">
              <img
                loading="lazy"
                src={CultureIcon}
                alt=""
                height="109"
                width="100"
              />
              <em>Culture</em>
            </div>
            <div className="pride__aspectWrap">
              <img
                loading="lazy"
                src={PeopleIcon}
                alt=""
                height="109"
                width="100"
              />
              <em>People</em>
            </div>
            <div className="pride__aspectWrap">
              <img
                loading="lazy"
                src={QualityIcon}
                alt=""
                height="109"
                width="100"
              />
              <em>Quality</em>
            </div>
          </section>
        </SectionContainer>
        <SectionContainer
          id="conductionStatement"
          color="darkNavy"
          type="fullWidth"
        >
          <p>
            We take pride in conducting our business with a broad perspective,
            considering our customers, our employees, and the communities in
            which we operate as we seek to continually Put People First and
            Delight the Customer.
          </p>
        </SectionContainer>

        {/* example angled container section */}
        {/* <AngledContainer id="veteranOwned" color="gray" type="fullWidth">
          <section className="inlineChildren">
            <section className="veteranOwned__headerWrap">
              <section className="veteranOwned__header">
                <div className="veteranBadge">
                  <img
                    src={VeteranBadge}
                    alt="Veteran Badge icon"
                    width="63"
                    height="160"
                  />
                </div>
                <SectionHeader
                  color="navy"
                  alignment="left"
                  size="small"
                  trim="true"
                >
                  Proudly <span>Veteran Owned</span>
                </SectionHeader>
                <SimpleButton
                  text="More on Our Veteran Programs"
                  url="/who-we-are#veteranPrograms"
                  type="gray"
                />
              </section>
            </section>
            <MainTextBlock>
              <p>textcontent
              </p>
            </MainTextBlock>
          </section>
        </AngledContainer> */}

        {/* Workspace section */}
        <SectionContainer id="workspaces" color="white" type="fullWidth">
          <SectionHeader color="navy" alignment="center" size="med" trim="true">
            Our <span>Workspaces</span>
          </SectionHeader>
          <section className="inlineChildren_mobile">
            <Workspaces />
          </section>
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default IndexPage
