import React, { Fragment, forwardRef } from 'react'
import * as styles from './WorkspaceCard.module.scss'
import Modal from "../../../Layouts/Modal/Modal"
import WorkspaceIcon from '../../WorkspaceIcons/WorkspaceIcon'
import SimpleButton from './../../Buttons/SimpleButton/SimpleButton'


const WorkspaceCard = forwardRef(({type, content, children}, ref) => {
    return(
        <Fragment>
            <article ref={ref} className={`${styles.workspace__card} ${styles[`workspace__card__${type}`]} `}>
                {children}
            </article>
            <Modal type='dark' ref={ref}>
                 <article className={`${styles[`workspaceModal__${type}`]} ${styles.workspaceModal}`}>
                    <section>
                        <section className={styles.workspaceModal_header}>
                            <WorkspaceIcon type={type} color='colored' format='split'/>
                            <h3>{content.title}</h3>
                            <address>{content.address1} <br/> {content.address2}</address>
                        </section>
                        
                        <div className={styles.workspaceModal_details}>
                            <p>{content.text}</p>
 
                            <section className={`inlineChildren_mobile ${styles.buttonWrap}`}>
                                <SimpleButton icon='clarity:phone-handset-solid' type='gray' url={`tel:+${content.phone}`} text={`Call ${content.phone}`} location='external'/>
                                { content.email ? 
                                    <SimpleButton icon='codicon:mail' type='gray' url={`mailto:${content.email}`} text='Email Precise' location='external'/>: ''}
                            </section>
                        </div>

                    </section>
                </article>
            </Modal>
        </Fragment>
       
    )
})


export default WorkspaceCard